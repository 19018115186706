@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@design';

@media only screen and (max-width: 600px) {
  .custom-subtitle {
    font-size: 0.9em;
    line-height: 1px;
  }
  #scroll-comparative-table {
    position: relative;
    .title-fixed {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}
